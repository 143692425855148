import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout, SEO } from "../components"
import useNetlifyForm from "../components/hooks/NetlifyFormHook"
import blackBackground from "../images/backgrounds/background_graphic_black.png"
import { joinUs } from "../content"
import { GatsbyImage } from "gatsby-plugin-image"

const JoinUs = () => {
  const { heroText, metadata, whyJoin } = joinUs
  const { inputs, handleInputChange, handleSubmit } = useNetlifyForm()
  const { background, formBackground } = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/heros/join-us-hero-background.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      formBackground: file(
        relativePath: { eq: "images/backgrounds/Background_Grid.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout title={metadata.title}>
      <SEO description={metadata.description} title={metadata.title} />
      <div className="background-image-wrapper">
        <GatsbyImage
          image={background.childImageSharp.gatsbyImageData}
          className="background-image"
        />
        <section className="why-join-hero">
          <div className="container">
            <div className="eight columns offset-by-one-and-one-half content-container">
              <h6>{heroText.heading}</h6>
              <h1>{heroText.description}</h1>
            </div>
          </div>
        </section>
      </div>

      <section
        className="text-section"
        style={{ backgroundImage: `url(${blackBackground})` }}
      >
        <div className="container">
          <div className="layout">
            <h3 className="white-text">{whyJoin.heading}</h3>
            <p className="white-text">{whyJoin.description}</p>
          </div>
        </div>
      </section>
      <div className="background-image-wrapper">
        <GatsbyImage
          image={formBackground.childImageSharp.gatsbyImageData}
          className="background-image white"
        />
        <section className="grid-form-section">
          <div className="container">
            <div className="ten columns offset-by-one">
              <h2 className="title">Apply</h2>
              <form
                className="black-text"
                onSubmit={handleSubmit}
                name="join-us-page-apply-form"
                id="join-us-page-apply-form"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <input
                  type="hidden"
                  name="form-name"
                  value="join-us-page-apply-form"
                />
                <div className="name-input">
                  <label htmlFor="name" className="hidden-label">
                    What&apos;s your name?
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="What's your name?"
                    onChange={handleInputChange}
                    value={inputs.name || ""}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="hidden-label">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your best email address"
                    onChange={handleInputChange}
                    value={inputs.email || ""}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message" className="hidden-label">
                    Message
                  </label>
                  <textarea
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Message"
                    onChange={handleInputChange}
                    value={inputs.message || ""}
                    required
                  />
                </div>
                <button className="blue-button" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default JoinUs
